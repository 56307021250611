@import '../../../config/';
@import '../../../mixins/typography';
@import '../../../mixins/box-shadow';
@import '../../../mixins/inputs';

.container {
  max-width: map-get($map: $spacing, $key: siteMaxWidth);
  margin: 0 auto;
  padding: map-get($map: $spacing, $key: sitePadding);
}

.callout {
  margin: 1rem 0;
}

.app {
  margin: 4rem 0;
  text-align: center;

  &-buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      width: 10rem;
      margin: 0 0.5rem;
    }
  }

  h4 {
    @include heading();
    margin-bottom: 0.75rem;
  }
}

.header {
  width: 100%;
  background-color: map-get($map: $colors, $key: foreground);
  padding: 2rem 0;

  &-container {
    max-width: map-get($map: $spacing, $key: siteMaxWidth);
    margin: 0 auto;
    padding: 0 map-get($map: $spacing, $key: sitePadding);
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    &:after {
      content: '';
      clear: both;
      display: table;
    }

    @include breakpoint(large, max) {
      // flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &-seller-info {
    padding-right: 2rem;
    width: 60%;

    &-container {
      display: flex;
      align-items: center;
    }

    h1 {
      @include heading();
    }

    h2 {
      @include copy();
      color: map-get($map: $colors, $key: grey);
      margin-top: 0.25rem;
    }

    p {
      margin-top: 1rem;
      white-space: pre-wrap;
      @include copy();

      @include breakpoint(large, max) {
        width: 100%;
        text-align: center;
      }
    }

    &-reviews {
      margin-top: 0.25rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    &-image {
      position: relative;
      min-width: 10rem;
      min-height: 10rem;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
    }

    @include breakpoint(large, max) {
      width: 100%;
      float: none;
      padding-right: 0;

      &-image {
        margin: 0 auto;
      }

      &-container {
        text-align: center;
        display: block;
      }
    }
  }

  &-seller-actions {
    width: 40%;
    padding-left: map-get($map: $spacing, $key: sitePadding) * 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;

    @include breakpoint(large, max) {
      margin-top: 1rem;
      justify-content: center;
      align-items: center;
      width: auto;
      padding-left: 0;
    }
  }

  &-seller-stats {
    width: 100%;
    max-width: 30rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    &-stat {
      text-align: center;

      h3 {
        @include copy($font-size: small);
        white-space: nowrap;
        margin-bottom: 0.25rem;
      }

      span {
        @include heading();
        color: map-get($map: $colors, $key: primary);
      }
    }
  }

  &-seller-badges {
    min-width: 100%;
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;

    &-pill {
      margin-right: 1rem;
      background-color: map-get($map: $colors, $key: light) !important;

      &:last-of-type {
        margin-right: 0;
      }
    }

    @include breakpoint(large, max) {
      justify-content: center;
    }
  }

  &-seller-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    &--follow {
      min-width: 15rem;

      button {
        padding: 1rem;
      }
    }

    &--action {
      width: 100%;
      max-width: calc(100% - 5rem);
      display: flex;
      gap: map-get($map: $spacing, $key: sitePadding);
      justify-content: center;

      > * {
        flex: 1;
      }
    }

    @include breakpoint(large, max) {
      justify-content: center;

      &--follow {
        min-width: 8rem;
      }

      &--action {
        justify-content: center;
        max-width: none;

        > * {
          flex: auto;
        }
      }
    }
  }
}

.image-active-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;

  @include breakpoint(large, max) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.pill {
  margin-top: -1.5rem;
  z-index: 1;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include box-shadow();
  padding: map-get($map: $spacing, $key: sitePadding);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  cursor: pointer;
  gap: map-get($map: $spacing, $key: sitePadding) / 2;
  flex-shrink: 0;

  &:hover {
    background-color: map-get($map: $colors, $key: background);
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;

    &-icon svg {
      width: 2rem;
      height: 2rem;
    }

    .title {
      @include type($size: small, $color: copyDark);
    }

    .count {
      @include type($size: small, $color: copyDark);
    }
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.review-modal-title {
  @include type($size: large, $color: copyDark, $font: copyBold);
  margin-bottom: 1rem;
  text-align: center;
}

.search-input-container {
  position: relative;
  flex-basis: 100%;
  @include input-reset();
  padding-bottom: 1rem;
}

.input-container {
  position: relative;

  form {
    position: relative;
  }
}

.input {
  @include input();
  z-index: 2;
  width: 100%;
  background-color: map-get($map: $colors, $key: foreground);

  &-active {
    background-color: map-get($map: $colors, $key: foreground);
  }

  @include breakpoint(medium, min) {
    &-active {
      border-bottom: 1px solid transparent !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 1.2rem;
  padding-right: 1rem;
  transform: translateY(-50%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 40px);
  pointer-events: none;
  color: map-get($map: $colors, $key: copyLight);
}

.placeholder-hidden {
  display: none;
}

.input-clear {
  @include copy();
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid map-get($map: $colors, $key: borderLight);
  background-color: map-get($map: $colors, $key: background);
  border-top-right-radius: map-get($map: $spacing, $key: borderRadius);
  border-bottom-right-radius: map-get($map: $spacing, $key: borderRadius);
  transition: background-color 0.25s;

  &--active {
    border-bottom: none;

    @include breakpoint(medium, min) {
      border-bottom-right-radius: 0;
    }
  }

  &:hover {
    background-color: darken(map-get($map: $colors, $key: background), 10%);
  }
}

.search-icon {
  height: 1.3rem;
  width: 1.3rem;
  position: absolute;
  top: calc(50% - 0.6rem);
  right: 0.75rem;
}
