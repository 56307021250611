@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';

.summary-container {
  display: flex;
  flex-direction: column;
  background-color: map-get($map: $colors, $key: foreground);
  padding: map-get($map: $spacing, $key: sitePadding) 0;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  margin-bottom: map-get($map: $spacing, $key: sitePadding);
  @include box-shadow;
}

.top-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 map-get($map: $spacing, $key: sitePadding);

  &-icon {
    width: 100px;
    padding-right: map-get($map: $spacing, $key: sitePadding);
  }

  &-header {
    @include type($size: medium, $color: copyDark, $font: copyBold);
    padding-bottom: map-get($map: $spacing, $key: sitePadding);

    @include breakpoint(small, max) {
      @include type($size: base, $color: copyDark, $font: copyBold);
    }
  }

  &-text {
    @include type($size: small, $color: copyLight);
  }
}

.divider {
  width: 100%;
  border-bottom: 1px solid map-get($map: $colors, $key: borderLight);
  margin: map-get($map: $spacing, $key: sitePadding) 0;
}

.bottom-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 map-get($map: $spacing, $key: sitePadding);
}

.tag-container {
  padding: map-get($map: $spacing, $key: sitePadding) map-get($map: $spacing, $key: sitePadding) 0
    map-get($map: $spacing, $key: sitePadding);
}

.avg-rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: map-get($map: $spacing, $key: sitePadding);
}

.text {
  @include type($size: small, $color: copyDark);
}

.text-tag {
  @include type($size: small, $color: copyLight);
}

.pill {
  margin-right: map-get($map: $spacing, $key: sitePadding);
  margin-top: map-get($map: $spacing, $key: sitePadding);
}

.heading {
  @include type($size: medium, $color: copyDark, $font: copyBold);
}

.pill-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.avg-title {
  @include type($size: jumbo, $color: primary, $font: copy);
  margin-right: map-get($map: $spacing, $key: sitePadding) / 2;
  margin-bottom: map-get($map: $spacing, $key: sitePadding) / 3;
}

.reviews-container {
  @include box-shadow;
  background-color: map-get($map: $colors, $key: foreground);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: map-get($map: $spacing, $key: sitePadding);
}

.seeMore {
  display: flex;
}

.spacer {
  height: map-get($map: $spacing, $key: sitePadding);
}

.stars {
  svg {
    width: 30px;
    height: 30px;
  }

  @include breakpoint(small, max) {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.star-rating {
  margin-bottom: map-get($map: $spacing, $key: sitePadding) / 3;
}

.spinner {
  margin: map-get($map: $spacing, $key: sitePadding);
  display: flex;
  justify-content: center;
}

.ratings-graph {
  width: 100%;

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: map-get($map: $spacing, $key: sitePadding) / 6;
  }

  &-text {
    @include type($size: tiny, $color: copyLight);
    margin-right: map-get($map: $spacing, $key: sitePadding) / 3;
    width: 15px;
    text-align: end;
  }
}

.tabs {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: map-get($map: $colors, $key: foreground);
    border-radius: map-get($map: $spacing, $key: borderRadius);
    margin-bottom: map-get($map: $spacing, $key: sitePadding);
    padding: (map-get($map: $spacing, $key: sitePadding));
  }

  &-item {
    border-bottom: 1px solid map-get($map: $colors, $key: borderLight);
    padding: map-get($map: $spacing, $key: sitePadding) / 1.5;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &--active {
      border-bottom: 1px solid map-get($map: $colors, $key: primary);
      @include type($size: small, $color: primary, $font: copyBold);
    }
  }

  &-text {
    @include type($size: small, $color: copyLight);

    &--active {
      @include type($size: small, $color: copyDark);
    }
  }
}

.blurred-text {
  position: relative;
  max-height: 8.5rem;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  }
}

.read-more-toggle {
  cursor: pointer;
  width: auto;
  padding: 0;
  @include type($size: base, $color: primary);

  svg {
    width: 1rem;
    height: auto;
  }

  &:hover {
    background-color: transparent;
  }
}
